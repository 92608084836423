header {
  min-height: 15vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: linear-gradient(to right, #50c878, #50c85a);
  color: white;
  justify-content: space-between;
  box-shadow: 2px 2px 4px #5b625b;
}

.nav-holder {
  width: 70%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.burger {
  width: 70%;
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 2.5rem;
  padding-right: 2rem;
}

#burger-btn {
  cursor: pointer;
}

.burger-div-border {
  margin-bottom: 10px;
}
.bars {
  transition: all 0.1s ease-in;
  padding: 2px;
  width: 50px;
  background-color: white;
  margin: 10px auto;
  display: block;
}

.animate-top {
  transform: translateY(14px) rotate(45deg);
}

.animate-bottom {
  transform: rotate(-45deg);
}

@media only screen and (max-width: 700px) {
  .burger {
    display: flex;
  }
  .nav-holder {
    display: none;
  }
}
