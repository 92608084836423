.App {
  text-align: center;
}

p {
  font-family: Arial;
}

:root {
  --titlePrimary: #50c878;
  --titleAction: #fff;
}

@keyframes actionHighlight {
  0% {
    text-shadow: none;
  }
  50% {
    text-shadow: 2px 2px 5px black;
  }
  100% {
    text-shadow: none;
  }
}
