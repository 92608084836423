.nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.nav p {
  font-family: "Lato";
  font-weight: bolder;
  margin-left: 40px;
  font-size: 25px;
  padding: 10px;
  border: 5px solid;
  border-radius: 2px;
  cursor: pointer;
  border-image: repeating-linear-gradient(
      135deg,
      white 0 10px,
      white 0 20px,
      white 0 30px
    )
    8;
  mask: conic-gradient(from 180deg at top 8px right 8px, #0000 90deg, #000 0)
      var(--_i, 200%) 0 /200% var(--_i, 8px) border-box no-repeat,
    conic-gradient(at bottom 8px left 8px, #0000 90deg, #000 0) 0
      var(--_i, 200%) / var(--_i, 8px) 200% border-box no-repeat,
    linear-gradient(#000 0 0) padding-box no-repeat;
  transition: 0.25s, mask-position 0.25s 0.25s;
}

.nav p:hover {
  --_i: 100%;
  transition: 0.25s, mask-size 0.25s 0.25s;
}

.nav p:first-child {
  animation: actionHighlight 3s infinite ease;
}

@media only screen and (max-width: 700px) {
  .nav p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 500px) {
  .nav p {
    font-size: 16px;
    margin-left: 20px;
  }
}
